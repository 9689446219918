// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';
import Link from '../../components/link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _DSF8655: ImageType,
    _DSF8656: ImageType,
    _DSF8659: ImageType,
    _DSF8660_DSF8661_DSF8662: ImageType,
    _DSF8669: ImageType,
    _DSF8672: ImageType,
    _DSF8676: ImageType,
    _DSF8681: ImageType,
    _DSF8686: ImageType,
    _DSF8690: ImageType,
    _DSF8692: ImageType,
  },
};

class Blog_2018_11_09_Moonflower_U_Turn extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter} toc>
        <p>
          Cold weather and short days brought us to Moab for the weekend. We
          wanted to stay dry so we eventually settled on Moonflower and U-Turn.
          Canyoneers for the day are me, Trent, and Brad.
        </p>
        <h2>Moonflower</h2>
        <CanyonRatingCard rating="3AII" title="Moonflower" />
        <p>
          <Link to="http://ropewiki.com/Moonflower_Canyon">Moonflower</Link> was
          new to all of us but is short and beginner-friendly so we figured we'd
          be okay. I quickly looked at the beta the night before and just got
          the longest rappel length which was 200'. Turns out our 2 200'ers were
          not quite long enough so I had a pair adventurous rappels. I'll
          explain my mistakes and what I did to fix them. First the trip report,
          though.
        </p>
        <Image
          image={data._DSF8655}
          caption="Approach near the Colorado River"
        />
        <p>
          We got an early start and followed the jeep trail up the hill near the
          Colorado River. It was absolutely beautiful and I was so glad to be
          there, despite the freezing temperatures. We warmed up quickly on the
          approach and passed a broken down jeep on the way. Turns out the jeep
          got multiple flats and the owners needed a special tool to fix one so
          they abandoned it for the day before and picked it up that morning.
          Glad they got everything worked out.
        </p>
        <Image
          image={data._DSF8656}
          caption="Broken-down jeep on the approach"
        />
        <Image
          image={data._DSF8659}
          caption="Near the top of the stair-master section where it starts to level out"
        />
        <Image
          image={data._DSF8660_DSF8661_DSF8662}
          caption="Show of Moab on the approach"
        />
        <p>
          We hand lined the first rappel while spotting each other down. Then we
          made our way to the only mandatory rappel in the canyon.
        </p>
        <Image
          image={data._DSF8669}
          caption="Top of first big rappel. Frozen puddle in the foreground and the Colorado in the background."
        />
        <Image image={data._DSF8672} caption="Brad on the first rappel" />
        <h3>Short Rope 1</h3>
        <p>
          Since Brad hadn't been rappelling in a while, I rigged the rappel
          slightly different from normal. Since it is a{' '}
          <GlossaryLink>long rappel</GlossaryLink> and I wanted to rig
          <GlossaryLink id="contingency-rigging">contingency</GlossaryLink>, I
          rigged a <GlossaryLink>Jester</GlossaryLink> and put the bend that
          joined the two ropes on the rappel side of the rope. I did this so
          that I wouldn't have to <GlossaryLink>pass a knot</GlossaryLink> if I
          had to lower someone. The two ropes I had were an Imlay Canyonero (
          <GlossaryLink>fat rope</GlossaryLink> in this instance) and Sterling
          C-IV (the <GlossaryLink>skinny rope</GlossaryLink>). I had everyone
          rappel on the fat rope because it provided more friction.
        </p>
        <p>
          After everyone else was down, I pulled out the Jester and went down
          <GlossaryLink>double-strand</GlossaryLink>. As I was going down, the
          fat rope was sticking in my rappel device and the skinny rope started
          slipping through the <GlossaryLink>rapide</GlossaryLink>. When I got
          near the bottom, the skinny rope was now 10' from the bottom of the
          rappel!
        </p>
        <p>
          To solve it, I tied a long <GlossaryLink>sling</GlossaryLink> to the
          short rope, had someone clip into it and provide a{' '}
          <GlossaryLink>meat anchor</GlossaryLink>, and then transitioned to the
          fat rope only and finished the rappel. I was able to do it safely and
          efficiently and was quite happy about that.
        </p>
        <p>
          The problems weren't quite finished when I got down, though. Now we
          had a rope with a knot on one side and a sling on the other. We tried
          pulling the sling down - re-equalizing the rope lengths - so that we
          could untie it and then pull the other rope down. For some reason we
          couldn't pull it down at all though. Our solution was to simply find
          higher ground where we could reach the knot and untie the sling. Then
          pulling down the rope was possible and we continued on our way.
        </p>
        <h3>Short Rope 2</h3>
        <p>
          Wanting to cleanse my palette from that blunder of a rappel, I wanted
          to do the final optional rappel and do it right. We walked around to
          get a good look at the rappel and couldn't find anyone below so we
          rigged and went down. Trent goes down first. He yells up that it's
          short again. Ugh.
        </p>
        <p>
          I had rigged this one with <GlossaryLink>biner block</GlossaryLink>{' '}
          and the bend was behind the rigging. I started to convert to lower and
          hear Trent yell, "Off rope!" So it wasn't short?
        </p>
        <p>
          Brad goes down and yells off rope and I'm on my way. I go over the
          edge, drop the pull side, and assess the situation. Both ropes are
          dangling 10' above ground. I am told it's okay so down I go. I again
          attach a sling to the <GlossaryLink>pull side</GlossaryLink> to get it
          long enough that Brad can grab it. I then grab onto it while he hikes
          up the hill, pulling me over to higher ground where I can safely
          de-rig. Again we manage a rappel with short ropes.
        </p>
        <p>
          As we were hiking back to the car, Brad told me that these 2 rappels
          were his longest rappels to date. I replied that these were my longest
          rappels with a 200' rope.
        </p>
        <h2>U-Turn</h2>
        <CanyonRatingCard rating="3AI" title="U-Turn" />
        <p>
          We were originally going to Pool Arch but because of the likelihood of
          having to wade through water we opted to do{' '}
          <Link to="http://ropewiki.com/U-Turn_Canyon">U-Turn Canyon</Link>{' '}
          instead.
        </p>
        <p>
          This was the only Park Avenue canyon the other two had yet to do. It
          is relatively short and with only a few hours of daylight left we
          chose it over Elephant Butte and Big Horn.
        </p>
        <Image image={data._DSF8676} caption="Top of U-Turn" />
        <Image
          image={data._DSF8681}
          caption="Round the corner to a great view of The Organ"
        />
        <p>
          Of the three Park Avenue canyons, U-Turn is my favorite. It has the
          best views, the best down climbs, and the best exit hike. What's not
          to love?
        </p>
        <Image
          image={data._DSF8686}
          caption="Another great view from the top of the final rappel"
        />
        <Image
          image={data._DSF8690}
          caption={
            <>
              Wile E. Coyote Rock - a cool{' '}
              <GlossaryLink>rock formation</GlossaryLink> a short distance away
              from the final rappel
            </>
          }
        />
        <p>
          I must admit, after that last canyon, I was pretty worried about the
          final rappel in this canyon. I knew the rappel was about 90' but had
          it in my mind that it was going to change to 110' so we wouldn't have
          enough rope if we used just a 200'er.
        </p>
        <p>
          We rigged and rapped and I found myself alone at the top again. I had
          them hold the bottom of the rappel side and pulled the rope up to set
          the length before I went. I then tied the other 200 to the end of the
          rappel rope and threw all of that down. Of course the other 200'er
          wasn't needed and just thumped down on the ground.
        </p>
        <p>
          So I sheepishly made my way down the rappel while they were cleaning
          up that extra rope. The pull was the easiest pull I have experienced
          on that rappel. I guess that means I <em>can</em> do things correctly
          when it comes to canyoneering.
        </p>
        <Image
          image={data._DSF8692}
          caption="Brad posing near Wile E. Coyote Rock"
        />
        <AllImagesLink id="2018-11-09-moonflower-u-turn" />
      </Layout>
    );
  }
}

export default withFrontMatter('2018-11-09-moonflower-u-turn')(
  Blog_2018_11_09_Moonflower_U_Turn
);

export const query = graphql`
  query {
    _DSF8655: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8655.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8656: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8656.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8659: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8659.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8660_DSF8661_DSF8662: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8660_DSF8661_DSF8662.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8669: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8669.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8672: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8672.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8676: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8676.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8681: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8681.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8686: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8686.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8690: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8690.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _DSF8692: file(
      relativePath: {
        eq: "images/blog/2018-11-09-moonflower-u-turn/_DSF8692.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
